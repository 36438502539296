import { ActionContext } from 'vuex'
import { ScreenState } from './models'
// import { api } from '@/services/api'

export default {
	// ============ fetch ===============
	// async fetchGroups(store: ActionContext<GroupState, string>, paramQuery: string): Promise<void> {
	// 	store.commit('setLoader', true)
	// 	store.commit('setGroups', [])		
	// 	store.commit('setError', false)	
	// 	await api.get(`/group${paramQuery}`).then(
	// 		response => {
	// 			if(response.data.list){
	// 				store.commit('setGroups', response.data)
	// 			}else{
	// 				store.commit('setError', 5000)
	// 			}
	// 		},
	// 		error => {
	// 			store.commit('setError', error)
	// 		}	
	// 	)
	// 	store.commit('setLoader', false)
	// },
	setSizes(store: ActionContext<ScreenState, string>, setSizes: Screen): void{
		store.commit('setWidthHeight', setSizes);
	}
}
	
