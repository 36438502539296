
// true = production | false = develop
export const SYSTEM_PROD_MODE = false

export const VERSION = 'Versão RC-1.1.6'

export const portalName = "Prefeitura Municipal de XXXX...."
export const portalSite = "www.xxxxxx.es.gov.br"

// http://localhost:8088/wp-json/wp/v2
// http://canaafm-com-br.umbler.net/wp-json/wp/v2
export const urlBase = {
	DEV:'https://apiradio.suporte.es/wp-json/wp/v2',
	PROD:'https://apiradio.suporte.es/wp-json/wp/v2',
}

export const urlApiTransparencia = {
	DEV:'https://apitransparencia2.laranjadaterra.es.gov.br',
	PROD:'https://apitransparencia2.laranjadaterra.es.gov.br',
}

export default {
	SYSTEM_PROD_MODE,
	VERSION,
	portalName,
	portalSite,
	urlBase,
	urlApiTransparencia
}