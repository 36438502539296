// import { User } from '@/molds/User'
import { ScreenState } from './models'

export default {
	getWidth(state: ScreenState): number {
		return state.width;
	},
	getHeight(state: ScreenState): number {
		return state.height;
	},
	isMobile(state: ScreenState): boolean {
		return (state.width <= 360)
	},
	isTablet(state: ScreenState): boolean {
		return (state.width > 360 && state.width <= 768)
	},
	isSmallScreen(state: ScreenState): boolean {
		return (state.width > 768 && state.width < 1366)
	},
	isDesktop(state: ScreenState): boolean {
		return (state.width >= 1366)
	},
	isBigSreen(state: ScreenState): boolean {
		return (state.width > 1920)
	},
}
	
