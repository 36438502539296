import { Component, Vue } from 'vue-property-decorator';

import links from '@/services/links'

import AudioPlayer from '@/components/audioPlayer/audioPlayer.vue';
import SocialIcons from '@/components/socialIcons/socialIcons.vue'
import SolicitacaoIcons from '@/components/solicitacaoIcons/solicitacaoIcons.vue';

import {
  Icons,
} from '@/components/base/index';

@Component({
  components: {
    Icons,
    SocialIcons,
    SolicitacaoIcons,
    AudioPlayer
  },
})
export default class NavBar extends Vue{
  isHoverable = true;
  isArrowless = false;

  screen_width = screen.width; 

  externalLink = links.mapLinksExternos

  goTo(link: string): void{
    if(link != this.$route.fullPath){
      this.$router.push(link);
    }
  }

  getRouteTo(item: any){
    if(item.link == undefined){
      return '/';
    }
    return item.link;
  }

  arrNavConfig = [
    {
      title: 'Início',
      isDropdown: false,
      link: '/'
    },
    {
      title: 'Programação',
      isDropdown: false,
      link: '/programacao'
    },
    {
      title: 'Notícias',
      isDropdown: false,
      link: '/lista/posts'
    },
    {
      title: 'Podcasts',
      isDropdown: false,
      link: '/lista/podcasts'
    },
    {
      title: 'Quem somos',
      isDropdown: false,
      link: '/contato'
    },
    // {
    //   title: 'Mais Conteudos',
    //   isDropdown: true,
    //   list: []
    // },
    // {
    //   title: 'Atos oficiais',
    //   link: 'https://materialdesignicons.com/',
    //   isExternalLink: true,  
    //   isDropdown: true,
    // }
  ]
}
