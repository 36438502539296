







import { Component, Vue } from 'vue-property-decorator';
import AudioPlayer from '@/components/audioPlayer/audioPlayer.vue';
import {Screen} from '@/store/modules/screen/models'

@Component({
  components: {
    AudioPlayer
  },
})

export default class App extends Vue{
  window: Screen = {width: 0, height: 0};
  
  created(): void{
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  destroyed(): void {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(): void{
    this.window.width = window.innerWidth;
    this.window.height = window.innerHeight;
    this.$store.dispatch('screen/setSizes', this.window);
    // this.$store.commit('screen/setWidthHeight', this.window);
  }



}
