import { Component, Vue } from 'vue-property-decorator'
import links from '@/services/links';
import {
  Icons,
} from '@/components/base/index';
  
@Component({
  components: {
    Icons
  }
})
export default class SocialIcons extends Vue {
  mapLinksExternos = links.mapLinksExternos;
  mapPagesWordpress = links.mapPagesWordpress;
}
  