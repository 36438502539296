import { Component, Vue } from 'vue-property-decorator'
import links from '@/services/links';
import {} from '@/components/base';
  
@Component({
  components: {}
})
export default class SolicitacaoIcons extends Vue {
  linksExternos = links.mapLinksExternos;
}
  