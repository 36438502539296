import { Component, Vue, Prop } from 'vue-property-decorator'
  
@Component({
  components: {}
})
export default class Icons extends Vue {
  @Prop({default: ''}) library!: string;
  @Prop() name!: string;
  @Prop() size!: string;
}
  