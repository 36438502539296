import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  next()
  window.scrollTo(0,0);
	//Proteção de página	
	// if(verifyToken()){
	// 	if(to.name == "login"){
	// 		next('/home')
	// 	} 
	// 	next()
	// }else{ //não há token
	// 	//Se não for página "login" proteger
	// 	if(to.name != "login"){
	// 		next('/')
	// 	}else{
	// 		next()
	// 	}
	// }
})

export default router
