import configs from "./variables";

export function printModeIsDEV(): void{
  if(!configs.SYSTEM_PROD_MODE){
    console.warn(" ======== [main] System in Devmode ========")
	}
}

export function getUrlBase(): string {
	if(configs.SYSTEM_PROD_MODE){
		return configs.urlBase.PROD
	} 
	return configs.urlBase.DEV
}

export function getUrlApiTransparencia(): string {
	if(configs.SYSTEM_PROD_MODE){
		return configs.urlApiTransparencia.PROD
	} 
	return configs.urlApiTransparencia.DEV
}

export default {
  getUrlBase,
  getUrlApiTransparencia,
  printModeIsDEV,
  ...configs,
}