import { Component, Vue } from 'vue-property-decorator'
  
import {
  Icons
} from '@/components/base';
  
@Component({
  components: {
    Icons
  }
})
export default class Footer extends Vue {

}
  