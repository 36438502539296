import { Component, Vue } from 'vue-property-decorator'

import { Icons } from '../base';

@Component({
  components: {
    Icons
  }
})
export default class AudioPlayer extends Vue {

  player: any = null;
  isPlay = false;

  get isMobile(): boolean{
    return this.$store.getters['screen/isMobile'];
  }

  mounted() {
    this.player = this.$refs.player1
  }

  clickPlay(){
    console.log('clickPlay');
    this.toggleAudio();
  }

  clickPause(){
    console.log('clickPause');
    this.pauseAudio();
  }

  toggleAudio(){
    if( !this.isPlay ){
      this.playAudio()
    }
    else{
      this.pauseAudio();
    }
  }

  playAudio(){
    this.player.play();
    this.isPlay = true;
  }

  pauseAudio(){
    this.player.pause();
    this.isPlay = false;
  }

}
  